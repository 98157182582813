var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Vendor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppVendor.shipAddNew.vendor'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"texto","options":_vm.vendors,"reduce":function (option) { return option.id; }},model:{value:(_vm.vendorShipData.proveedorId),callback:function ($$v) {_vm.$set(_vm.vendorShipData, "proveedorId", $$v)},expression:"vendorShipData.proveedorId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Vendor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('AppVendor.shipAddNew.boardingTemplate'),"label-for":"fiPlantilla"}},[_c('b-form-file',{attrs:{"id":"fiPlantilla","accept":".xls, .xlsx","placeholder":_vm.$t('AppVendor.shipAddNew.noFileSelected'),"browse-text":_vm.$t('Lists.browse'),"state":_vm.getValidationState(validationContext)},model:{value:(_vm.vendorShipData.templateFile),callback:function ($$v) {_vm.$set(_vm.vendorShipData, "templateFile", $$v)},expression:"vendorShipData.templateFile"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AppVendor.shipAddNew.attachedFiles')}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-dropdown',{attrs:{"variant":"outline-primary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})]},proxy:true}],null,true)},_vm._l((_vm.documentTypes),function(documentType){return _c('b-dropdown-item',{key:documentType.id,on:{"click":function($event){{
                      _vm.selectedDocumentTypeId = documentType.id,
                      _vm.selectedDocumentTypeName = documentType.texto
                    }}}},[_vm._v(" "+_vm._s(documentType.texto)+" ")])}),1)],1),_c('b-form-file',{attrs:{"disabled":!_vm.selectedDocumentTypeId,"accept":".pdf","placeholder":_vm.$t('AppVendor.shipAddNew.noFileSelected'),"browse-text":_vm.$t('Lists.browse')},model:{value:(_vm.selectedDocument),callback:function ($$v) {_vm.selectedDocument=$$v},expression:"selectedDocument"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary","disabled":!_vm.selectedDocumentTypeId},on:{"click":function($event){return _vm.addFile(_vm.selectedDocument, _vm.selectedDocumentTypeId, _vm.selectedDocumentTypeName)}}},[_c('feather-icon',{attrs:{"icon":"UploadIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Lists.Add'))+" ")],1)],1)],1)],1),_c('b-table',{ref:"refDocumentsTable",staticClass:"mb-2",attrs:{"items":_vm.submitData.documentos,"fields":_vm.documentsTableFields}})],1),_c('hr')],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Lists.saveChanges'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }