<template>
  <b-card
    no-body
    class="mb-0"
  >
    <!--Vendor Ship List-->
    <vendor-ship-list
      v-if="mode === 1"
      :mode.sync="mode"
    />

    <!--Vendor Ship Add New -->
    <vendor-ship-add-new
      v-if="mode === 2"
      :mode.sync="mode"
    />
  </b-card>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  BCard,
} from 'bootstrap-vue'
import VendorShipList from './VendorShipList.vue'
import VendorShipAddNew from './VendorShipAddNew.vue'

export default {
  components: {
    BCard,

    VendorShipList,
    VendorShipAddNew,
  },
  setup() {
    /**
    * Mode
    * 1 List
    * 2 New
    * 3 Edit
    */
    const mode = ref(1)
    return {
      mode,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
