<template>
  <b-card>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            md="12"
          >
            <!-- Vendor -->
            <validation-provider
              #default="{ errors }"
              name="Vendor"
              rules="required"
            >
              <b-form-group
                :label="$t('AppVendor.shipAddNew.vendor')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="vendorShipData.proveedorId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="vendors"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Vendor template-->
            <validation-provider
              #default="validationContext"
              name="Vendor"
              rules="required"
            >
              <b-form-group
                :label="$t('AppVendor.shipAddNew.boardingTemplate')"
                label-for="fiPlantilla"
              >
                <b-form-file
                  id="fiPlantilla"
                  v-model="vendorShipData.templateFile"
                  accept=".xls, .xlsx"
                  :placeholder="$t('AppVendor.shipAddNew.noFileSelected')"
                  :browse-text="$t('Lists.browse')"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="12"
          >
            <b-form-group :label="$t('AppVendor.shipAddNew.attachedFiles')">
              <b-input-group>
                <b-input-group-prepend>
                  <b-dropdown variant="outline-primary">
                    <template #button-content>
                      <feather-icon icon="Edit2Icon" />
                    </template>
                    <b-dropdown-item
                      v-for="documentType in documentTypes"
                      :key="documentType.id"
                      @click="{
                        selectedDocumentTypeId = documentType.id,
                        selectedDocumentTypeName = documentType.texto
                      }"
                    >
                      {{ documentType.texto }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group-prepend>
                <b-form-file
                  v-model="selectedDocument"
                  :disabled="!selectedDocumentTypeId"
                  accept=".pdf"
                  :placeholder="$t('AppVendor.shipAddNew.noFileSelected')"
                  :browse-text="$t('Lists.browse')"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    :disabled="!selectedDocumentTypeId"
                    @click="addFile(selectedDocument, selectedDocumentTypeId, selectedDocumentTypeName)"
                  >
                    <feather-icon icon="UploadIcon" />
                    {{ $t('Lists.Add') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-table
              ref="refDocumentsTable"
              :items="submitData.documentos"
              :fields="documentsTableFields"
              class="mb-2"
            />
          </b-col>
          <hr>
        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BFormGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
} from '@validations'
import i18n from '@/libs/i18n'
import vendorsService from '@/services/vendor.service'
import optionsService from '@/services/option.service'
import useCommon from '@/libs/common'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BFormInvalidFeedback,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    mode: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const { fetchVendors, fetchVendorDocumentTypes } = optionsService()
    const { createVendorShip } = vendorsService()
    const common = useCommon()
    const blankVendorShipData = {
      proveedorId: null,
      templateFile: null,
    }

    const refDocumentsTable = ref(null)
    const vendors = ref([])
    const documentTypes = ref([])
    const selectedDocument = ref(null)
    const selectedDocumentTypeId = ref(0)
    const selectedDocumentTypeName = ref(0)
    const documentsTableFields = [
      {
        key: 'documentoProveedorTipoId',
        label: 'ID',
      },
      {
        key: 'nombre',
        label: i18n.t('AppVendor.shipColumns.userName'),
      },
    ]
    const vendorShipData = {
      proveedorId: null,
      templateFile: null,
    }
    const submitData = {
      proveedorId: vendorShipData.proveedorId,
      documentos: [],
    }
    const addFile = (file, id, name, callback) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => {
        const arrayBuffer = reader.result
        const bytes = new Uint8Array(arrayBuffer)
        const byteArray = []
        // eslint-disable-next-line no-restricted-syntax
        for (const element of bytes) {
          byteArray.push(element)
        }
        submitData.documentos.push({ documentoProveedorTipoId: id, nombre: name, archivos: [byteArray] })
        refDocumentsTable.value.refresh()
        callback()
      }
    }

    fetchVendors(data => {
      vendors.value = data
    }, null)
    fetchVendorDocumentTypes(data => {
      documentTypes.value = data
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(blankVendorShipData)

    const onSubmit = () => {
      submitData.proveedorId = vendorShipData.proveedorId

      addFile(vendorShipData.templateFile, 1, 'TPLET ShipmentTemplate', () => {
        createVendorShip(submitData, () => {
          emit('update:mode', 1)
          common.toastSuccess(i18n.t('AppVendor.shipAddNew.shipRegistered'))
        })
      })
    }

    return {
      vendorShipData,
      vendors,
      documentTypes,
      selectedDocument,
      selectedDocumentTypeId,
      selectedDocumentTypeName,
      refDocumentsTable,
      // documents,
      documentsTableFields,
      submitData,
      addFile,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      required,
    }
  },
}
</script>
<style lang="sass">

</style>
