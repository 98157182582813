<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Lists.Show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $t('Lists.Entries') }}</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Lists.Search')"
            />
            <b-button
              variant="primary"
              @click="addNewVendorShip"
            >
              <span class="text-nowrap">{{ $t('AppVendor.shipAddNew.newShip') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--Table-->
    <b-table
      ref="refVendorShipListTable"
      class="position-relative"
      :items="ships"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('Lists.NoMatchingRecordsFound')"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(fechaAlta)="data">
        <b-media vertical-align="center">
          {{ data.item.fechaAlta | formatDate }}
        </b-media>
      </template>
      <template #cell(fechaRevisionEjecutivo)="data">
        <b-media vertical-align="center">
          {{ data.item.fechaRevisionEjecutivo | formatDate }}
        </b-media>
      </template>
      <template #cell(fechaRevisionCliente)="data">
        <b-media vertical-align="center">
          {{ data.item.fechaRevisionCliente | formatDate }}
        </b-media>
      </template>
      <template #cell(fecha)="data">
        <b-media vertical-align="center">
          {{ data.item.fecha | formatDate }}
        </b-media>
      </template>
      <!-- Column: Show Details -->
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="
            row.toggleDetails(),
            fetchShipDetails(row.item.embarqueId)
          "
        >
          <template #cell(fechaAlta)="data">
            <b-media vertical-align="center">
              {{ data.item.fechaAlta | formatDate }}
            </b-media>
          </template>
          <template #cell(fechaRevisionEjecutivo)="data">
            <b-media vertical-align="center">
              {{ data.item.fechaRevisionEjecutivo | formatDate }}
            </b-media>
          </template>
          <template #cell(fechaRevisionCliente)="data">
            <b-media vertical-align="center">
              {{ data.item.fechaRevisionCliente | formatDate }}
            </b-media>
          </template>
          <template #cell(fecha)="data">
            <b-media vertical-align="center">
              {{ data.item.fecha | formatDate }}
            </b-media>
          </template>

          <span class="vs-label">{{ row.detailsShowing ? $t('AppVendor.shipList.hide') : $t('Lists.Show') }}</span>
        </b-form-checkbox>
      </template>
      <!--Details-->
      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-table
                ref="refVendorShipInvoicesTable"
                :items="vendorShipInvoices"
                :fields="vendorShipInvoicesTableColumns"
              >
                <!-- Column: Show Details -->
                <template #cell(show_details)="rowInvoice">
                  <b-form-checkbox
                    v-model="rowInvoice.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="
                      rowInvoice.toggleDetails(),
                      fetchShipInvoiceDetails(rowInvoice.item.facturaId)
                    "
                  >
                    <span class="vs-label">{{ row.detailsShowing ? $t('AppVendor.shipList.hide') : $t('Lists.Show') }}</span>
                  </b-form-checkbox>
                </template>
                <!--Details-->
                <template #row-details="rowInvoice">
                  <b-card>
                    <b-row>
                      <b-col
                        md="12"
                        class="mb-1"
                      >
                        <b-table
                          :items="vendorShipInvoiceItems"
                          :fields="vendorShipInvoiceItemTableColumns"
                        />
                      </b-col>
                    </b-row>
                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="rowInvoice.toggleDetails"
                    >
                      {{ $t('AppVendor.shipList.hideDetails') }}
                    </b-button>
                  </b-card>
                </template>
              </b-table>
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-table
                ref="refVendorShipAwbsTable"
                :items="vendorShipAwbs"
                :fields="vendorShipAwbsTableColumns"
              />
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-table
                ref="refVendorShipDocumentsTable"
                :items="vendorShipDocuments"
                :fields="vendorShipDocumentsTableColumns"
              >
                <template #cell(document)="data">
                  {{ data.item.documentoProveedorTipo.clave }}-{{ data.item.documentoProveedorTipo.descripcionAlterna }}
                </template>
                <template #cell(download)="data">
                  <b-button
                    variant="success"
                    @click="downloadFile(data.item.embarqueDocumentoId, data.item.nombre + data.item.extension)"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                    />
                    {{ $t('AppVendor.shipList.download') }}
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            {{ $t('AppVendor.shipList.hideDetails') }}
          </b-button>
        </b-card>
      </template>
      <!--Actions-->
      <template #cell(actions)="rowInvoice">
        <b-button
          variant="outline-primary"
          @click="downloadFile(rowInvoice)"
        >
          <feather-icon
            icon="SaveIcon"
          />
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </template>
    </b-table>

    <!--Pagination-->
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.from }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalVendorShips"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormInput, BButton, BTable, BPagination,
  BFormCheckbox, BCard,
} from 'bootstrap-vue'
import { ref, computed, watch } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'
import vendorService from '@/services/vendor.service'
import { saveAs } from 'file-saver'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormCheckbox,
    BCard,

    vSelect,
  },
  setup() {
    const refVendorShipListTable = ref(null)
    const perPage = ref(10)
    const perPageOptions = ref([10, 25, 50, 100])
    const currentPage = ref(1)
    const totalVendorShips = ref(0)
    const dataMeta = computed(() => {
      const localItemsCount = refVendorShipListTable.value ? refVendorShipListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalVendorShips.value,
      }
    })
    const searchQuery = ref('')
    const sortBy = ref('EmbarqueId')
    const isSortDirDesc = ref('')
    const tableColumns = ref([
      {
        key: 'show_details',
        label: i18n.t('AppVendor.shipList.details'),
      },
      {
        key: 'embarqueId',
        label: i18n.t('AppVendor.shipList.sequence'),
        sortable: true,
        sortKey: 'EmbarqueId',
      },
      {
        key: 'nombreUsuario',
        label: i18n.t('AppVendor.shipList.userName'),
        sortable: true,
        sortKey: 'NombreUsuario',
      },
      {
        key: 'fechaAlta',
        label: i18n.t('AppVendor.shipList.upDate'),
        sortable: true,
        sortKey: 'FechaAlta',
      },
      {
        label: i18n.t('AppVendor.shipList.revisionExecutiveDate'),
        key: 'fechaRevisionEjecutivo',
        sortable: true,
        sortKey: 'FechaRevisionEjecutivo',
      },
      {
        label: i18n.t('AppVendor.shipList.revisionCustomerDate'),
        key: 'fechaRevisionCliente',
        sortable: true,
        sortKey: 'FechaRevisionCliente',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ])
    const ships = ref([])
    const {
      fetchVendorShips, fetchVendorShipInvoices,
      fetchVendorShipAwbs, fetchVendorShipDocuments,
      fetchVendorShipInvoiceItems,
    } = vendorService()

    const fetchVendorShipsData = () => {
      fetchVendorShips({
        searchQuery: searchQuery.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        limit: perPage.value,
        sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
      }, data => {
        ships.value = data.embarques
        totalVendorShips.value = data.total
      })
    }

    watch([currentPage, perPage, searchQuery], () => {
      fetchVendorShipsData()
    })

    fetchVendorShipsData()

    const refVendorShipInvoicesTable = ref(null)
    const vendorShipInvoices = ref([])
    const vendorShipInvoicesTableColumns = [
      {
        key: 'show_details',
        label: i18n.t('AppVendor.shipList.details'),
      },
      {
        key: 'numero',
        label: i18n.t('AppVendor.shipList.bill'),
      },
      {
        key: 'fecha',
        label: i18n.t('AppVendor.shipList.date'),
      },
      {
        key: 'monedaClave',
        label: i18n.t('AppVendor.shipList.coin'),
      },
      {
        key: 'incotermClave',
        label: i18n.t('AppVendor.shipList.keyIncoterm'),
      },
      {
        key: 'ordenCompra',
        label: i18n.t('AppVendor.shipList.purchaseOrder'),
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
      },
    ]
    const vendorShipAwbs = ref([])
    const vendorShipAwbsTableColumns = [
      {
        key: 'guiaProveedor',
        label: i18n.t('AppVendor.shipList.guide'),
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
      },
    ]
    const vendorShipDocuments = ref([])
    const vendorShipDocumentsTableColumns = [
      {
        key: 'document',
        label: i18n.t('AppVendor.shipList.document'),
      },
      {
        key: 'download',
        label: i18n.t('AppVendor.shipList.download'),
      },
    ]

    const vendorShipInvoiceItems = ref([])
    const vendorShipInvoiceItemTableColumns = ref([
      {
        key: 'parteClave',
        label: i18n.t('AppVendor.shipList.part'),
      },
      {
        key: 'paisOrigenClave',
        label: i18n.t('AppVendor.shipList.countryOrigin'),
      },
      {
        key: 'cantidad',
        label: i18n.t('AppVendor.shipList.quantity'),
      },
      {
        key: 'unidadMedidaClave',
        label: i18n.t('AppVendor.shipList.unitMeasure'),
      },
      {
        key: 'descripcion',
        label: i18n.t('AppVendor.shipList.description'),
      },
      {
        key: 'precioUnitario',
        label: i18n.t('AppVendor.shipList.unitPrice'),
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
      },
    ])

    const fetchShipDetails = embarqueId => {
      fetchVendorShipInvoices({ embarqueId }, data => {
        vendorShipInvoices.value = data
      })

      fetchVendorShipAwbs({ embarqueId }, data => {
        vendorShipAwbs.value = data
      })

      fetchVendorShipDocuments({ embarqueId }, data => {
        vendorShipDocuments.value = data
      })
    }

    const fetchShipInvoiceDetails = facturaId => {
      fetchVendorShipInvoiceItems({ facturaId }, data => {
        vendorShipInvoiceItems.value = data
      })
    }

    return {
      refVendorShipListTable,
      perPage,
      perPageOptions,
      currentPage,
      totalVendorShips,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      ships,
      fetchShipDetails,
      fetchShipInvoiceDetails,
      refVendorShipInvoicesTable,
      vendorShipInvoicesTableColumns,
      vendorShipInvoices,
      vendorShipAwbs,
      vendorShipAwbsTableColumns,
      vendorShipDocuments,
      vendorShipDocumentsTableColumns,
      vendorShipInvoiceItems,
      vendorShipInvoiceItemTableColumns,
    }
  },
  methods: {
    addNewVendorShip() {
      this.$emit('update:mode', 2)
    },
    downloadFile(idEmbarqueDocumento, fileName) {
      axios.get(`Proveedores/Embarques/Documento/${idEmbarqueDocumento}/Descarga`, { responseType: 'blob' })
        .then(response => {
          const file = new File([response.data], fileName)
          saveAs(file, fileName)
        })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
